/*
 * @Date: 2021-07-05 14:52:06
 * @LastEditTime: 2022-08-04 16:02:45
 */
import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import lazyLoad from './lazyLoad';
import { routeAuth } from './utils';

/** 创建路由 */
const BasicRoute = async () => {
  await routeAuth();
  return (
    <Router basename={process.env.REACT_APP_ROUTENAME}>
      <Route exact path="/ad1" component={lazyLoad(() => import('../template/ad1'))}></Route>
      <Route exact path="/ad2" component={lazyLoad(() => import('../template/qrcode/ad2'))}></Route>
      <Route exact path="/ad3" component={lazyLoad(() => import('../template/qrcode/ad3'))}></Route>
      <Route exact path="/ad4" component={lazyLoad(() => import('../template/qrcode/ad4'))}></Route>
      <Route exact path="/ad5" component={lazyLoad(() => import('../template/qrcode/ad5'))}></Route>
      <Route exact path="/ad_fineCourse_321" component={lazyLoad(() => import('../template/fineCourse/321'))}></Route>
      <Route exact path="/delicious-food" component={lazyLoad(() => import('../template/youke-activity/delicious_food'))}></Route>
      <Route exact path="/learn-plan" component={lazyLoad(() => import('../template/learn-plan/index'))}></Route>
      <Route exact path="/master-detail" component={lazyLoad(() => import('../template/learn-plan/masterCourse/detail'))}></Route>
      <Route exact path="/studyQrCode" component={lazyLoad(() => import('../template/studyQrCode/index'))}></Route>
      <Route exact path="/homeQrCode" component={lazyLoad(() => import('../template/homeQrCode/index'))}></Route>
      <Route exact path="/planerInfo" component={lazyLoad(() => import('../template/planerInfo/planerInfo'))}></Route>
      <Route exact path="/evaluationResults" component={lazyLoad(() => import('../template/evaluationResults/evaluationResults'))}></Route>
      <Route exact path="/transferScanCode" component={lazyLoad(() => import('../template/scanCode/index'))}></Route>
      <Route exact path="/testActivity" component={lazyLoad(() => import('../template/testActivity/index'))}></Route>
      <Route exact path="/testActivity/:name" component={lazyLoad(() => import('../template/testActivity/index'))}></Route>
      <Route exact path="/distributionPlatform" component={lazyLoad(() => import('../template/distributionPlatform/index'))}></Route>
      <Route exact path="/feedback" component={lazyLoad(() => import('../template/testActivity/components/feedback/index'))}></Route>
    </Router>
  );
};
/* 导出BasicRoute并于index.js入口文件中引用 */
export default BasicRoute;
