import qs from 'qs';
import { Ishare_info } from './tool.i';
import { default as _widnowClass } from './window/window';
/** lzwk项目api，后续会把tools业务逻辑封装好的api扔到里面。 */
export const lzwk = _widnowClass;
export const commonMeth = new (class {
  constructor() {
    //
  }
  /** 判断是否是荔枝微课APP */
  isLZWKAPP() {
    if (/lycheer/.test(navigator.userAgent.toLocaleLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  /** 获取query参数 */
  getJsonFromUrl() {
    const query = window.location.search.substr(1);
    if (!query) return false;
    const result = {};
    query.split('&')?.map(x => Object.assign(result, { [x.split('=')[0]]: decodeURIComponent(x.split('=')[1]) }));
    return result;
  }
  // 获取用户的基本信息
  getUserInfo = () => {
    return JSON.parse(localStorage.getItem('Session/account') || '');
  };
  // 获取url中的某个参数
  getUrlParam = (targetParam: string) => {
    return qs.parse(window.location.search.replace('?', ''))[targetParam]?.toString();
  };

  // 删除url中的某个参数
  removeURLParameter(parameter: string, url = window.location.href) {
    const urlparts = url.split('?');
    if (urlparts.length >= 2) {
      const prefix = encodeURIComponent(parameter) + '=';
      const pars = urlparts[1].split(/[&;]/g);
      for (let i = pars.length; i-- > 0; ) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }
      history.replaceState(null, '', urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : ''));
    }
  }

  setToken(token: string) {
    localStorage.setItem('_token', token);
    return token;
  }
  // 判断是否是pc端
  isPcEnv = () => {
    const session: any = window.Session;
    return session.payPlatform === 'pc' ? true : false;
  };
  is_Pc() {
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      return false;
    } else {
      return true;
    }
  }
  /**
   * @description 倒计时
   * @param {Date} 结束时间戳
   */
  interval(countdown: number) {
    const days = Math.floor(countdown / (24 * 3600 * 1000)); // 计算出小时数
    const leave1 = countdown % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
    const hours = Math.floor(leave1 / (3600 * 1000)); // 计算相差分钟数
    const leave2 = leave1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
    const minutes = Math.floor(leave2 / (60 * 1000)); // 计算相差秒数
    const leave3 = leave2 % (60 * 1000); // 计算小时数后剩余的毫秒数
    const second = Math.round(leave3 / 1000);
    const time = days + '天' + hours + '时' + minutes + '分' + second + '秒';
    const obj = {
      days,
      hours: hours >= 10 ? String(hours) : `0${hours}`,
      minutes: minutes >= 10 ? String(minutes) : `0${minutes}`,
      second: second >= 10 ? String(second) : `0${second}`,
      time,
    };

    /** TODO: 倒计到1s会跳到0s而不是下一分钟的60s，这个后面做一下迭代优化。 */
    if (second < 0) {
      return { ...obj, msg: 'overdue' };
    }
    return { ...obj, msg: 'success' };
  }

  /**
   * @description 人气数单位换算
   */
  renderPopular(num: number) {
    if (num < 1e4) {
      return num;
    } else {
      return `${+(num / 1e4).toFixed(1)}万`;
    }
  }

  /**
   * 价格转换
   * @param price 金额（分）
   * @param tail 小数点后跟几位
   */
  changePrice = (price: number, tail: number = 2) => {
    price = price > 0 ? price : 0;
    const _p = (price / 100).toFixed(2);
    return {
      price: _p,
      yuan: _p.split('.')[0],
      fen: _p.split('.')[1].substring(0, tail),
    };
  };

  /**
   * @description 时间格式转换
   */
  TimeStream(timestamp: number, type?: string) {
    const date: Date = new Date(timestamp);
    const Y = date.getFullYear();
    const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    const nowTime: Date = new Date();
    const timeToString = nowTime.toString();
    switch (type) {
      case 'YMD': // 年-月-日
        return Y + '.' + M + '.' + D;
      case 'MD': // 月-日
        return M + '-' + D;
      case 'MD_HM': // 月-日
        return M + '-' + D + ' ' + h + ':' + m;
      case 'YMD_HMS': // 月-日 时：分：秒
        return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s;
      case 'CN_YMD': // x年x月x日
        return Y + '年' + M + '月' + D + '日';
      case 'CN_YMD_HM': // x年x月x日
        return Y + '年' + M + '月' + D + '日' + ' ' + h + ':' + m;
      case 'CN_MD_HM': // x月x日 h:m
        return M + '月' + D + '日' + ' ' + h + ':' + m;
      case 'CN_HM': // x月x日
        return `${M}月${D}日`;
      case 'hm': // 时:分
        return `${h}:${m}`;
      case '-D': // 天数差
        return Math.floor((timestamp * 1000 - Date.parse(timeToString)) / 1000 / 3600 / 24);
      case '-H': // 小时差
        return Math.ceil((timestamp * 1000 - Date.parse(timeToString)) / 1000 / 3600);
      case 'standard':
        return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s;
      default:
        // 年-月-日 时:分
        return Y + '-' + M + '-' + D + ' ' + h + ':' + m;
    }
  }

  /**
   * 获取随机名
   * @param {Num} len 需要生成的随机数长度
   */
  random_string(len: number) {
    len = len || 32;
    const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz';
    const maxPos = chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
      pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  }
  /**
   * 生成随机req_msg_id === 随机数+时间戳
   * @param {Num} len 需要生成的随机数长度
   */
  randomSign(len: number) {
    return `${this.random_string(len)}-${parseInt(String(Math.floor(Math.random() * Date.now()) / 1000))}`;
  }

  /**
   * 过滤数组重复项,获取不同项
   * @param arr1
   * @param arr2
   */
  getArrDifference(arr1: number[], arr2: number[]) {
    return arr1.concat(arr2).filter(function (v: any, i: any, arr: string | any[]) {
      return arr.indexOf(v) === arr.lastIndexOf(v);
    });
  }
  // [min, max)随机数包含min, 不包含max
  random(min: number, max: number) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  /**
   * 范围随机数
   * @param num 随机数量
   * @param max 最大值
   * @param min 最小值
   * @filter 可选 过滤项
   */
  randoms(num: number, max: number, min: number, filter?: number | number[]): number[] {
    const fun: number | any = () => {
      const res = Math.ceil(Math.random() * (max - min) + min);
      if (Array.isArray(filter)) {
        const result = filter.filter(v => v !== res);
        return result.length === filter.length ? res : fun();
      } else {
        return res !== filter ? res : fun();
      }
    };
    return Array.from({ length: num }).map(() => fun());
  }

  /**
   * 节流：根据时间进行节流，防止一定时间内多次触发
   * @param {Function} fn 函数方法
   * @param {Number} delay 时间
   */
  throttle(...arg: any) {
    const [fn, delay] = arg;
    let prev = Date.now();
    return function (this: any) {
      const context = this;
      const now = Date.now();
      if (now - prev > delay) {
        fn.call(context, arg);
        prev = Date.now();
      }
    };
  }

  /**
   * 防抖：触发后一定时间内不会再次触发，如果一直运行则一直重置setTimeout延时操作
   * @param {Function} fn
   * @param {Number} interval 时间
   */
  debounce(...arg: any) {
    const [fn, interval] = arg;
    let timer: NodeJS.Timeout;
    const gapTime = interval || 1000;
    return function (this: any) {
      clearTimeout(timer);
      const context = this;
      timer = setTimeout(function () {
        fn.call(context, arg);
      }, gapTime);
    };
  }

  /**
   * @param {Function} scrollCallback 是否正在滚动的回调
   * @param {Number} interval 时间
   */
  debounceCallback(...arg: any) {
    const [scrollCallback, interval] = arg;
    let timer: NodeJS.Timeout | undefined;
    const gapTime = interval || 1000;
    return function (this: any) {
      if (!timer) {
        scrollCallback(true);
      }
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = undefined;
        scrollCallback(false);
      }, gapTime);
    };
  }

  /**
   * 获取皮肤scrollTop
   * @param name <.class>/<#id>
   */
  skinTop(name: string): Promise<number> | Promise<any> {
    return new Promise((r, j) => {
      this.loop(3, 100, () => {
        const listTop: any = document.querySelectorAll(name)?.[0];
        if (listTop?.offsetTop) {
          return r(listTop.offsetTop);
        } else {
          return j('该轮轮询未获得结果');
        }
      });
    });
  }

  /**
   * 指定时间，次数范围轮询callback方法
   * @param { function } callback
   * @param { number } num
   * @param { number } time
   */
  loop(num: number, time: number, callback: (id?: number) => any) {
    const start = (id: number, p?: any) => {
      p = !p ? execute(id) : p.then(() => execute(id));
    };
    for (let i = 0; i < num; i++) start(i);
    function execute(id?: number) {
      return new Promise(resolve => setTimeout(resolve, time)).then(() => callback(id));
    }
  }

  /** 在url上拼接参数 */
  addURLParam = (url: string, name: string, value: string) => {
    //3个参数，要添加参数的url,参数的名称,参数的值
    url += url.indexOf('?') == -1 ? '?' : '&'; //判断url中是否有'?'
    url += encodeURIComponent(name) + '=' + encodeURIComponent(value); //拼接
    return url;
  };

  /** 分享 */
  initShare = (result: { share_info: Ishare_info }, callback?: Function) => {
    if (window.Session.weixinApi) {
      // window.Session.weixinApi.initJSSDK();
      let url: string = window.location.href.split('?')[0],
        json: any = Object.assign(this.getJsonFromUrl(), { scene: 'sharelink' /* 分享场景值 */ });
      for (let key in json) {
        url = this.addURLParam(url, key, json[key]); //传参
      }
      const shareData = {
        title: result.share_info.share_title,
        desc: result.share_info.share_description,
        imgUrl: result.share_info.share_icon,
        link: url,
      };
      const timeShareData = {
        ...shareData,
        link: `https://share.lizhiweike.com?redirect_url=${encodeURIComponent(shareData.link)}`,
      };
      window.Session.weixinApi.wxShare([shareData, timeShareData], callback);
    }
  };

  /** 校验电话号码 */
  checkPhone(phone: string) {
    if (/^1[3456789]\d{9}$/.test(phone)) {
      return true;
    } else {
      return false;
    }
  }
})();

/**
 * 当在当前节点操作时，阻止指定节点受到滚动穿透
 * @param id 阻止穿透的节点id
 */
export const preventMove = (current_id: string, origin_id: string, Toast: (option: any) => void) => {
  const _currentDom = document.getElementById(current_id);
  const _originDom = document.getElementById(origin_id);

  if (_currentDom && _originDom) {
    let _top = -document.documentElement.scrollTop || _originDom.getBoundingClientRect().top;

    _currentDom.ontouchstart = () => {
      // setTimeout(() => {
      //   Toast({ content: `开启禁止穿透: ${document.documentElement.scrollTop}, ${_originDom.getBoundingClientRect().top}, ${_top}`, duration: 1000 });
      // }, 1000);

      console.log('----- 开启禁止穿透 -----', document.documentElement.scrollTop, _originDom.getBoundingClientRect(), _top);
      Object.assign(_originDom.style, { position: 'fixed', top: `${_top}px` });

      _currentDom.ontouchstart = null;
    };

    _currentDom.ontouchmove = () => {
      console.log('----- move禁止穿透 -----', _top);
      Object.assign(_originDom.style, { position: 'fixed', top: `${_top}px` });
    };

    _currentDom.ontouchend = () => {
      setTimeout(() => {
        Toast({ content: `解除穿透限制: ${document.documentElement.scrollTop}, ${_originDom.getBoundingClientRect().top}, ${_top}`, duration: 1000 });
      }, 1000);

      console.log('----- 解除穿透限制 -----', document.documentElement.scrollTop, _originDom.getBoundingClientRect(), _top);
      Object.assign(_originDom.style, { position: '', top: 0 });
      setTimeout(() => {
        scrollTo({ top: -_top });
      }, 1000);

      _currentDom.ontouchend = null;
    };
  }
};

export const fromData = (data: any) => {
  let formData = new FormData();
  Object.entries(data).map(([k, v]: any) => {
    formData.append(k, v);
  });
  return formData;
};

/** 图片链接转为base64格式 图片加载有误（如跨域）则使用默认logo头像 */
export const imgUrlToBase64 = (url: string, callback: (arg: string) => any) => {
  let Img = new Image(),
    dataURL = '';
  Img.src = url + '?v=' + Math.random();
  Img.setAttribute('crossOrigin', 'Anonymous');
  Img.onload = function () {
    let canvas = document.createElement('canvas'),
      width = Img.width,
      height = Img.height;
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d')?.drawImage(Img, 0, 0, width, height);
    dataURL = canvas.toDataURL('image/jpeg');
    return callback ? callback(dataURL) : null;
  };
  Img.onerror = function (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) {
    return callback ? callback('https://staticqc-operating.lycheer.net/image/weike-GntzXs-304675518.png') : null;
  };
};

/** 查询出现最多的重复元素，可能出现多个多数元素。 */
export const repeatsElement = (arr: (number | string)[]) => {
  const len = arr.length;
  let obj: { [k: string]: number } = {};
  for (let i = 0; i < len; i++) {
    if (arr[i] !== undefined) {
      obj[arr[i]] = obj[arr[i]] ? ++obj[arr[i]] : (obj[arr[i]] = 1);
    }
  }
  const objArr = Object.values(obj).sort();
  const objLen = objArr.length;
  const objMax = objArr[objLen - 1];
  const objNum = Object.entries(obj)
    .map(([k, v]) => v == objMax && k)
    .filter(x => x);
  return objNum.map(v => Number(v));
};
