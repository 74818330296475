/*
 * @Date: 2020-04-03 11:14:21
 * @LastEditTime: 2022-08-25 20:27:54
 * @Description: 埋点接口
 */

import { request } from 'api/http-youke-activity';
import { lzwk } from 'assets/tools';
import { store } from 'store';

interface Istate {
  element?: any;
  page?: string;
  scene?: string;
  ab?: string;
  theme?: string | number;
  inviter_id?: string;
  app?: string;
  page_id?: number;
  project?: string;
}

/** 页面类型 */
export enum IlogsPage {
  home = 'home',
  myCourse = 'my_course',
  newUser = 'new_user_welfare',
  channelDetail = 'channel_detail',
  pay_success = 'pay_success',
  lecture = 'learning',
  pay_channel = 'pay_channel',
  re_pay_channel = 're_pay_channel',
  coupon = 'coupon',
  op = 'op',
}

/** 触发的课程 */
export type Ievt_params = {
  index?: number | string; // 当前显示在总列表下标
  ch_id?: number; // 专栏id
  lec_id?: number; // 课程id
  model?: string; // 推荐模型
  context?: string; // 直接把上一个页面的ubt 塞进去
  order_id?: number; // 订单id
  url?: string; //  链接
  str_value?: 'success' | 'fail'; // 事件结果参数用于页面加载性能的表达; 区分开evt_type跳转之后的element
  evt_type?: loadStatus | boxsStatus | liveStatus | learnAgain | payStatus | qrcodeType | new_type; // 状态
  object_type?: string; // 商品类型(专栏、课程、学习卡...)
  object_id?: number; // 商品id
  value?: number; // 页面加载耗时/浏览页面占比(浏览埋点中)
  inviter_id?: string; // 邀请id
  content?: string; // 拉起支付失败的原因
  duration?: number; // 页面停留时间
  plan_id?: number; // 学习计划id
};

/** 页面加载成功还是失败 */
export type loadStatus = 'success' | 'failed';
/** 领取礼盒状态 */
export type boxsStatus = /** 已经触发 */ 'open' | /** 未触发 */ 'close' | /** 未关注 */ 'unsub' | /** 成功领取 */ 'get' | /** 跳转学习页 */ 'learning';
/** 直播模块状态 */
export type liveStatus = /** 已经订阅 */ 'sub' | /** 未订阅 */ 'unsub' | /** 正在直播 */ 'living';
/** 继续学习 */
export type learnAgain = /** 已经触发 */ 'learn_last' | /** 未触发 */ 'all_course';
/** 支付 */
export type payStatus = /** 用户未支付就退出 */ 'close' | /** 用户重新支付 */ 're_pay' | /** 用户支付失败 */ 'fail';
/** 购买成功二维码展示类型 */
export type qrcodeType = /** 官方号 */ 'official_account' | /** 个人号 */ 'personal_account';
/** 额外新增 */
export type new_type = 'select' | 'finish' | 'retest' | 'mbti' | 'familyship' | 'enneagram';

// 扩展传参
export type Ioption = {
  ubt?: string;
  ab?: number | string; // 接入策略id
  page?: string;
};

export interface Ilogs {
  ubt: string;
  evt: string;
  evt_params: Ievt_params;
}

export interface Icustom {
  app: string;
  page: string;
  project: string;
  scene?: string;
  inviter_id?: string;
  ab?: string; // 区分页面级别的AB测
  theme?: string;
}

const Api_common_logs = class {
  state: Istate = {};
  /** 初始化log自定义模式 app project page 必传 */
  customInit(params: Icustom) {
    this.state = {
      ...params,
      scene: params?.scene ?? '0',
      inviter_id: params?.inviter_id ?? '0',
      ab: params?.ab ?? 'a',
      theme: params?.theme ?? '1',
    };
    store.Logs.setLogs({ [window.location.pathname]: this.state });
    return store.Logs.getLogs();
  }
  // app.project[.scene.ab.theme]-page[.element] evt事件名 evt_params事件参数 option更改ubt头默认数据
  sets(element: string, evt: string, evt_params: Ievt_params, option?: Ioption) {
    const { app, scene, page, ab, theme, project, inviter_id, page_id } = store.Logs.getLogs();
    if (!app) return undefined;
    const _element = element ? `.${element}` : '';
    return {
      ubt: option?.ubt || `${app}.${project}.${scene}.${option?.ab || ab}.${theme}-${option?.page || page}${_element}`,
      evt,
      evt_params,
    };
  }

  /** 页面加载事件，埋点说明：用户在页面加载成功时上报的点 */
  // load(element: string, params: Ievt_params, option?: Ioption) {
  //   const data = this.sets(element, 'perf_load', { value: new Date().getTime() - window.startTime, ...params }, option);
  //   console.log('加载埋点', data);
  //   this.fetch(data);
  //   return data;
  // }

  /**
   * 批量处理
   * @param event 事件
   */
  task(event: string, element?: string, params?: Ievt_params, option?: Ioption) {
    const { inviter_id } = store.Logs.getLogs();
    return this.sets(element ?? '', event, { inviter_id, ...params }, option);
  }

  /** 页面访问事件，埋点说明：用户在进入页面时触发的埋点 */
  view(element?: string, params?: Ievt_params, option?: Ioption) {
    const { inviter_id } = store.Logs.getLogs();
    const data = this.sets(element ?? '', 'view', { inviter_id, ...params }, option);
    console.log('view埋点', data);
    this.fetch(data);
    return data;
  }

  /** 推荐课程模块的曝光事件，埋点说明：用户曝光的埋点 */
  set_impress(element: string, params: Ievt_params, option?: Ioption) {
    const { inviter_id } = store.Logs.getLogs();
    return this.sets(element, 'impress', { inviter_id, ...params }, option);
  }

  /** 推荐课程模块的曝光事件，埋点说明：用户曝光的埋点 */
  impress(logsData: (Ilogs | undefined)[] | undefined) {
    const data = logsData?.filter(x => x);
    if (data?.length) {
      this.fetch(data);
    }
  }

  /** 推荐课程模块的点击事件，埋点说明：用户在进入页面时触发的埋点 */
  click(element?: string, params?: Ievt_params, option?: Ioption) {
    const { inviter_id } = store.Logs.getLogs();
    const data = this.sets(element ?? '', 'click', { inviter_id, ...params }, option);
    console.log('点击埋点', data);
    this.fetch(data);
    return data;
  }

  /** 弹窗/banner之类的曝光埋点, 区别页面级别使用的view事件 */
  pimpress(element: string, params?: Ievt_params, option?: Ioption) {
    const data = this.sets(element, 'impress', { ...params }, option);
    console.log('曝光埋点', data);
    this.fetch(data);
    return data;
  }

  /** 浏览埋点，埋点说明：页面停留时间、浏览内容百分比 */
  browse(element: string, params?: Ievt_params, option?: Ioption) {
    const data = this.sets(element, 'browse', { ...params }, option);
    console.log('浏览埋点', data);
    this.fetch(data);
    return data;
  }

  /** 会员卡埋点 */
  pay_month_bag_click(element: string, params?: Ievt_params, option?: Ioption) {
    const data = this.sets(element, 'pay_month_bag_click', { ...params }, option);
    console.log('支付会员卡埋点', data);
    this.fetch(data);
    return data;
  }

  /** 支付模块点击返回取消支付时触发的埋点 */
  cancel(element: string, params: Ievt_params, option?: Ioption) {
    const { inviter_id } = store.Logs.getLogs();
    const data = this.sets(element, 'cancel', { inviter_id, ...params }, option);
    console.log('取消埋点', data);
    this.fetch(data);
    return data;
  }

  /** 支付模块点击事件 此处将拉起支付成功与pay_channel_click合并，减少接口的调用次数 */
  pay_channel_click(element: string, params: Ievt_params, option?: Ioption) {
    const { inviter_id } = store.Logs.getLogs();
    const value = lzwk.loadPageTime(new Date().getTime());
    let dataList: any[] = [];
    const data = this.sets(element, 'pay_channel_click', { inviter_id, ...params, value }, { ubt: option?.ubt });
    const perfPayData = this.sets('pay_btn', 'perf_pay', { evt_type: 'success', value });
    dataList = dataList.concat(data, perfPayData);
    console.log('pay_channel_click与成功支付合并埋点', dataList);
    this.fetch(dataList);
    return dataList;
  }

  // 拉起支付【一定要添加】页面性能指标
  perf_pay(element: string, params: Ievt_params) {
    const data = this.sets(element, 'perf_pay', {
      evt_type: 'fail',
      object_type: 'channel',
      object_id: params.ch_id,
      content: params.content,
    });
    console.log('perf_pay埋点', data);
    this.fetch(data);
    return data;
  }

  fetch(data?: Ilogs | (Ilogs | undefined)[]) {
    const requestData = data instanceof Array ? data : [data];
    if (data) {
      request.post<any>(`${process.env.REACT_APP_LOGS}/api/data_collector/logs`, { data: requestData });
    }
  }
};

export default new Api_common_logs();
