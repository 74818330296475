import { Ilzwk } from './index.i';
import { Ievt_params } from 'api/logs/logs';
/**
 * @classdesc 放置lzwk内容，并注册到window（注意：项目业务逻辑专用，别乱扔东西。）
 * NOTE: 但避免混乱，请勿直接使用/修改 window.lzwk内容
 */
export default new (class extends Ilzwk {
  impressOption: { [x: string]: Ievt_params[] } = {};
  constructor() {
    super();
    Object.assign(this, window.lzwk);
    this.init();
  }
  reloadTime() {
    this.startTime = new Date().getTime();
  }
  private init() {
    /** 默认初始化loadTime */
    this.loadTime = new Date().getTime() - this.startTime;
  }
  loadPageTime(endTime: number) {
    this.loadTime = endTime - window.lzwk.startTime;
    // console.log('t:', endTime - window.lzwk.startTime);
    return this.loadTime;
  }

  /** 缓存impressOption，避免不更新问题。 */
  set_impressOption(option: Ievt_params[], name: string) {
    Object.assign(this.impressOption, { [name]: option });
    return this.impressOption;
  }
})();
