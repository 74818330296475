import { request } from 'api/http-youke-activity';
import { Distribution } from './index.i';

/**
 * @description 分销api文档
 * @link https://gitlab.weike.fm/oc/oc-docs/-/blob/dev_zp/operating/分销体系/分销API.md
 */
const Api_Distribution = {
  /** 拉取用户绑定的手机号：只需要绑定一次永久保持登录状态 */
  getUserTelephone: () => {
    return request.get<Distribution.telData>(`/oc_operating/api/distribution/user/pvy`);
  },
  /** 获取验证码 */
  captcha: (params: { telephone: string }) => {
    return request.post<any>(`/oc_operating/api/captcha`, params);
  },
  /** 登录 */
  distribution: (params: { telephone: string; captcha: string }) => {
    return request.post<any>(`/oc_operating/api/login/distribution`, params);
  },
  /** 获取总收入、可提现金额、冻结金额 */
  cash: () => {
    return request.get<Distribution.Icash>(`/oc_operating/api/distribution/cash`);
  },
  /** 分销列表信息  */
  orderInfo: () => {
    return request.get<Distribution.IorderInfo>(`/oc_operating/api/distribution/order/info`);
  },
  /** 生成短链
   * 测试环境：域名：https://weike.yiqishequn.com;  wxapp_id：wx8a674e7e7d438485
   * 线上环境：域名：https://weike.jingxinclass.com; wxapp_id：wxab92d4f5f04eb6e3
   */
  createShort: async (params: { skin_id: string; phone: string }) => {
    const Access: Window['Session']['sessionData'] = await new Promise(resolve => window.Session?.getToken(resolve));
    Object.assign(params, { wk_token: Access.token, wxapp_id: `${process.env.REACT_APP_WXAPPID}` });
    return request.get<Distribution.IshortLink>(`${process.env.REACT_APP_DOMAIN}/api/data/distribution/url`, { params });
  },
  /** 绑定分销课程 */
  cro: (params: { activity_id: number; shop_id: number; skin_id: number }) => {
    return request.post<any>(`/oc_operating/api/distribution/user/cro`, params);
  },
};
export default Api_Distribution;
