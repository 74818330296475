/*
 * @Date: 2020-09-01 20:03:42
 * @LastEditTime: 2020-09-21 16:33:40
 * @Description:
 */
import { Ievt_params } from 'api/logs/logs';
export class Ilzwk {
  startTime!: number;
  loadTime!: number;
  clientHeight!: number;
  impressOption!: { [option: string]: Ievt_params[] };
  //   loadPageTime!: (endTime: number) => void;
}
