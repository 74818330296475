/*
 * @Date: 2021-09-11 13:58:09
 * @LastEditTime: 2021-09-11 14:38:55
 * @Description:
 */

import Api from 'api';
import { commonMeth } from 'assets/tools';
import { loadJS } from 'utils/tools';

/** 路由授权 */
export const routeAuth = async () => {
  const __wxjs_environment = sessionStorage.getItem('__wxjs_environment') || (window as any).__wxjs_environment;
  return new Promise((r, j) => {
    if (__wxjs_environment === 'miniprogram') {
      document.getElementsByTagName('body')[0].style.background = '#fff';
      try {
        if (commonMeth.getUrlParam('__token')) {
          const _token = commonMeth.getUrlParam('__token');
          sessionStorage.setItem('__wxjs_environment', 'miniprogram');
          localStorage.setItem('_token', _token || '');
          Api.Api_common.base_account_info({ token: _token || '' })
            .then(result => {
              localStorage.setItem('Session/account', JSON.stringify(result.data));
              commonMeth.removeURLParameter('__token');
              return r('已经获取用户权限：base_account_info，并删除url中的__token');
            })
            .catch(e => j(`base_account_info接口错误：${e}`));
        }
        return r('当前链接无__token，属于已授权');
      } catch (error) {
        console.error(error);
        return j(error);
      }
    } else {
      loadJS('https://staticqc.lycheer.net/session/session-v4.3.17.min.js', function () {
        window.Session.getToken(async account => {
          console.log('account:', account, document.referrer);
          return r('window.Session.getToken：success');
        });
      });
    }
  });
};
