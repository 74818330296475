/*
 * @Date: 2021-08-19 20:18:37
 * @LastEditTime: 2021-09-11 15:28:51
 * @Description:
 */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import './utils/rem';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { loadJS, loadProxyConfig } from 'utils/tools';
import { commonMeth } from 'assets/tools';
import 'swiper/swiper.min.css';

if (process.env.NODE_ENV == 'production') {
  loadJS('https://m.lizhiweike.com/static/js/alloy-lever.js');
}

if (/localhost/g.test(window.location.host) && process.env.REACT_APP_API === '/m') {
  loadProxyConfig();
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
