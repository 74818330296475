/*
 * @Date: 2021-08-19 20:18:37
 * @LastEditTime: 2022-08-26 20:55:42
 */
export function copy(copyText: string) {
  const textareaEl = document.createElement('textarea');
  textareaEl.setAttribute('readonly', 'readonly'); // 防止手机上弹出键盘
  textareaEl.value = copyText;
  document.body.appendChild(textareaEl);
  textareaEl.select();
  const res = document.execCommand('copy'); // 执行复制命令,本身会返回true或者是false，可以根据此返回设置相应的提示
  if (res) {
    console.log('复制成功', copyText);
  }
  document.body.removeChild(textareaEl); // 最后从文档中移除DOM元素
}

export function loadJS(url: string, callback?: () => void) {
  const script = document.createElement('script'),
    fn = callback || function () {};
  // script.type = 'text/javascript';
  script.onload = function () {
    fn();
  };
  script.src = url;
  document.getElementsByTagName('body')[0].appendChild(script);
}

// 添加代理线上的环境配置
export function loadProxyConfig() {
  const scriptElm = document.createElement('script');
  scriptElm.innerHTML = `window.SessionGlobalVariable = {
      SESSION_BASE_URL: 'https://open.lizhiweike.com',
    };
    window.clientId = 'operation_launch'`;
  document.getElementsByTagName('head')[0].appendChild(scriptElm);
}

export function isNumber(value: number) {
  return typeof value === 'number' && !isNaN(value);
}

/**
 * 根据时间进行节流，防止一定时间内多次触发
 * @param {Function} fn 函数方法
 * @param {Number} delay 时间
 */
export function throttle<T extends any[]>(fn: (e: T) => any, delay: number) {
  let prev = Date.now();
  return function (this: any, ...args: T) {
    let context = this;
    let now = Date.now();
    if (now - prev > delay) {
      fn.call(context, args);
      prev = Date.now();
    }
  };
}
export function debounce<T extends any[]>(fn: (e: T) => any, interval: number, immediate?: boolean) {
  let timer: NodeJS.Timeout | null;
  let gapTime = interval || 1000;
  return function (this: any, ...args: T) {
    if (timer) {
      clearTimeout(timer);
    }
    let context = this;

    if (immediate && !timer) {
      fn.call(context, args);
    }

    timer = setTimeout(function () {
      // console.log(interval);
      fn.call(context, args);
      timer = null;
    }, gapTime);
  };
}

export const bulletRun = (bulletMainDom: HTMLElement, bulletBoxDom: Element, speed = 1) => {
  let totalWidth = 0;

  if (!bulletMainDom || !bulletBoxDom) return;
  bulletBoxDom.childNodes.forEach(item => {
    if (item.nodeType === 1) {
      totalWidth += parseInt(window.getComputedStyle(item as Element).width.split('px')[0]);
    }
  });
  if (totalWidth > bulletMainDom.offsetWidth) {
    let x = 0;
    let animation = () => {
      x -= speed;
      // console.log(x, -(totalWidth / 2));
      (bulletBoxDom as HTMLElement).style.cssText = `transform: translate3d(${x}px, 0, 0)`;
      if (x <= -(totalWidth / 2)) {
        x = 0;
        (bulletBoxDom as HTMLElement).style.cssText = `transform: translate3d(${x}px, 0, 0)`;
      }
      requestAnimationFrame(animation);
    };
    animation();
  }
};

/** 获取query参数 */
export function JsonFromUrl(): { [x: string]: string } | undefined {
  const query = window.location.search.substr(1);
  if (!query) return undefined;
  let result = {};
  query.split('&').forEach(x => Object.assign(result, { [x.split('=')?.[0]]: decodeURIComponent(x.split('=')?.[1]) }));
  return result;
}

/**
 * 秒转时分秒
 * @param {string} value 秒
 */
export const formatSeconds = (value: number) => {
  let secondTime = value; // 秒
  let minuteTime = 0; // 分
  let hourTime = 0; // 小时
  let dayTime = 0;
  if (secondTime >= 60) {
    minuteTime = parseInt(String(secondTime / 60));
    secondTime = parseInt(String(secondTime % 60));
    if (minuteTime >= 60) {
      hourTime = parseInt(String(minuteTime / 60));
      minuteTime = parseInt(String(minuteTime % 60));
      if (hourTime >= 24) {
        dayTime = parseInt(String(hourTime / 24));
        hourTime = parseInt(String(hourTime % 24));
      }
    }
  }
  let result = '' + (parseInt(String(secondTime)) < 10 ? '0' + parseInt(String(secondTime)) : parseInt(String(secondTime)));
  // if (minuteTime > 0) {
  result = '' + (parseInt(String(minuteTime)) < 10 ? '0' + parseInt(String(minuteTime)) : parseInt(String(minuteTime))) + ':' + result;
  // }
  if (hourTime > 0) {
    result = '' + (parseInt(String(hourTime)) < 10 ? '0' + parseInt(String(hourTime)) : parseInt(String(hourTime))) + ':' + result;
  }

  return { dayTime, hourTime, minuteTime, secondTime, result };
};

export const isIphone = () => {
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    //判断iPhone|iPad|iPod|iOS
    return true;
  }
  return false;
};

// 判断是否是pc端
export const isPcEnv = () => {
  const session: any = window.Session;
  return session.payPlatform === 'pc' ? true : false;
};

/**
 * 生成随机数
 * @param {Num} len 需要生成的随机数长度
 */
export const random_string = (len: number) => {
  //获取随机名
  len = len || 32;
  let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz';
  let maxPos = chars.length;
  let pwd = '';
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
};

/**
 * 生成随机req_msg_id === 随机数+时间戳
 * @param {Num} len 需要生成的随机数长度
 */
export const randomSign = (len: number) => {
  return `${random_string(len)}-${parseInt(String(Math.floor(Math.random() * Date.now()) / 1000))}`;
};
/** 增加Splash节点 */
export function appendSplash() {
  // const __wxjs_environment = sessionStorage.getItem('__wxjs_environment') || (window as any).__wxjs_environment;
  // if (__wxjs_environment !== 'miniprogram') {
  const str = `<div id="splash">
      <div class="splash-container">
        <img src="https://static-cos.lycheer.net/xupload/20220402_logo.png" alt="" />
        <p class="title">十方教育</p>
        <p id="indexTips">成就技能  助力成长</p>
        <div class="sk-wave">
          <div class="sk-rect sk-rect1"></div>
          <div class="sk-rect sk-rect2"></div>
          <div class="sk-rect sk-rect3"></div>
          <div class="sk-rect sk-rect4"></div>
          <div class="sk-rect sk-rect5"></div>
        </div>
        <div id="example"></div>
      </div>
    </div>`;
  const id: any = document.getElementById('append-root');
  id.innerHTML = str;
  // }
}

/** 去掉Splash加载画面 */
export function removeSplash(time?: number) {
  const splash = window.document.getElementById('splash');
  if (navigator.appName === 'Microsoft Internet Explorer') {
    setTimeout(() => {
      splash && (splash as any).removeNode(true);
    }, time || 200);
  } else {
    setTimeout(() => {
      splash && splash.remove();
    }, time || 500);
  }
}

export function jumpVersion() {
  const id: string = localStorage.getItem('lzwk_id') || '0';
  const last: number = Number(id?.substr(id.length - 1, 1));
  // 0-4进A版，5-9进B版
  const version = last <= 4 ? 'a' : 'b';
  return version;
}

export const isRegion = (num: number, min: number, max: number) => num >= min && num <= max;
