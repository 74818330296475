/*
 * @Date: 2020-07-02 23:27:40
 * @LastEditTime: 2020-10-13 17:17:31
 * @Description:
 */
import { observable, action, toJS } from 'mobx';
interface ILlogsinfo {
  [x: string]: {
    app?: string;
    scene?: string;
    page?: string;
    ab?: string;
    theme?: string | number;
    object_id?: string;
    inviter_id?: string;
    project?: string;
    page_id?: number;
    strategy_id?: number;  // 策略id
  };
}

/**
 * @description 埋点信息
 */
export class Logs {
  /** 活动数据 */
  @observable info: ILlogsinfo = {};
  /** 存储活动数据 */
  @action setLogs(info: ILlogsinfo | undefined) {
    info && Object.assign(this.info, info);
  }
  /** 获取活动数据 */
  @action getLogs() {
    return toJS(this.info)[window.location.pathname];
  }
}
