/*
 * @Date: 2021-07-05 10:53:47
 * @LastEditTime: 2022-07-21 16:02:06
 * @Description: api统一输出
 */

import Api_common from './common';
import Api_common_logs from './logs/logs';
import Api_learnPlan from './learnPlan';
import Api_Test from './testActivity';
import Api_Distribution from './distribution';

const Api = {
  Api_common,
  Api_common_logs,
  Api_learnPlan,
  Api_Test,
  Api_Distribution
};

export default Api;
