/*
 * @Date: 2021-08-19 17:20:32
 * @LastEditTime: 2022-08-14 19:51:41
 */
import { request } from 'api/http-youke-activity';
import { Evaluation, IcampInfo, Icode, IgetTest, IShareStatus, IsubmitTest, theme } from './index.i';
const Api_Test = {
  /**
   * 评测题
   * @link https://gitlab.weike.fm/oc/oc-docs/-/blob/dev_zp/operating/裂变测评/测评裂变API.md#测试信息记录
   */
  test: {
    get: (name?: theme) => {
      return request.get<IgetTest.Data>(`/oc_operating/api/share/record/${name ?? 'evaluation'}`);
    },
    /**
     * type: 题材类型
     * 1 == mbti
     * 2 == family
     * 3 == ****(暂时未定)
     * data: 答案
     */
    post: <T>(params: { record: { type?: 1 | 2; data?: T; score?: number; v2Data?: T } }, name?: theme) => {
      return request.post<IsubmitTest.Data>(`/oc_operating/api/share/record/${name ?? 'evaluation'}`, params);
    },
  },
  bindPhone: {
    /**
     * 是否绑定手机号
     */
    get: () => request.get<Evaluation.StatusData>(`/oc_operating/api/phone`),
    /**
     * 去绑定手机号
     */
    post: (params: { telephone: string; captcha: string }) => request.post<any>(`/oc_operating/api/phone`, params),
  },
  /**
   * 发送手机验证码
   */
  captcha(params: { telephone: string }) {
    return request.post<any>(`/oc_operating/api/captcha`, params);
  },
  /**
   * 用户分享状态
   * @link https://gitlab.weike.fm/oc/oc-docs/-/blob/dev_zp/operating/裂变测评/测评裂变API.md#用户状态
   */
  share_status() {
    return request.get<IShareStatus.Data>(`/oc_operating/api/share/user/status`);
  },
  /**
   * 分享码生成
   * @link https://gitlab.weike.fm/oc/oc-docs/-/blob/dev_zp/operating/裂变测评/测评裂变API.md#分享码生成
   */
  invite() {
    return request.get<Icode.Data>(`/oc_operating/api/share/invite`);
  },
  /**
   * 发送分享码行为记录
   * @link https://gitlab.weike.fm/oc/oc-docs/-/blob/dev_zp/operating/裂变测评/测评裂变API.md#发送分享码行为记录
   */
  setCode(code: string) {
    return request.get<any>(`/oc_operating/api/share/invite/${code}/`);
  },
  /**
   * 接受分享邀请
   * @link https://gitlab.weike.fm/oc/oc-docs/-/blob/dev_zp/operating/裂变测评/测评裂变API.md#接受分享邀请
   */
  evaluation(code: string, name?: theme) {
    return request.get<any>(`/oc_operating/api/share/invited/${name ?? 'evaluation'}/${code}/`);
  },
  /**
   * 测试记录数量
   * @link https://gitlab.weike.fm/oc/oc-docs/-/blob/dev_zp/operating/裂变测评/测评裂变API.md#测试记录数量
   */
  count() {
    return request.get<{ total: number }>(`/oc_operating/api/share/record/all/count`);
  },
  /**
   * 获取用户所在营期
   */
  all_period() {
    return request.get<IcampInfo[]>(`/oc_operating/api/all_period`);
  },
  /**
   * @description 问题反馈表单
   * @link https://gitlab.weike.fm/oc/oc-docs/-/blob/dev_zp/operating/用户问题反馈/用户意见采集API.md#问题反馈表单
   */
  feedbackProblem(params: { module: string; desc: string; contact?: string }) {
    return request.post<any>(`/oc_operating/api/form/problem`, params);
  },
};

export default Api_Test;
