/*
 * @Date: 2020-12-22 14:15:10
 * @LastEditTime: 2021-08-24 19:59:43
 * @Description:
 */
import React, { useState, useEffect } from 'react';
import BasicRoute from './route';
import './App.less';

function App() {
  const [router, setRouter] = useState<any>();
  useEffect(() => {
    BasicRoute().then((result: any) => setRouter(result));
  }, []);
  if (!router) return <div></div>;
  return (
    <div className="App" id="App">
      {router}
    </div>
  );
}

export default App;
