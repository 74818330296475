/*
 * @Date: 2021-08-19 17:20:32
 * @LastEditTime: 2021-09-06 17:17:18
 * @Description:
 */
import { request } from 'api/http-youke-activity';
import { UrlLinkData, IAccountInfo, Iupload, IsSubscribedData } from './index.i';
const Api_common = {
  /** token换取用户信息 */
  base_account_info(params: { token: string }) {
    return request.get<any>(`/platform/api/account/base_account_info`, { params });
  },
  /**获取会员信息
   * @link https://gitlab.weike.fm/oc/oc-docs/blob/master/operating/会员体系/接口文档/获取会员信息.md#1-获取会员信息
   */
  account_info() {
    return request.get<IAccountInfo.Data>(`/membership/api/membership/account_info`);
  },
  /**
   * 获取跳转小程序的短链
   * @param link https://gitlab.weike.fm/oc/oc-docs/-/blob/yyl/operating/dispatch/小程序路由链接接口.md#2-获取小程序链接
   * @param tag  scheme / link
   * @link  测试环境Django后台 https://dbg.lizhiweike.com/oc_operating/admin/dispatch/xcxsetting/
   * @link  线上环境Django后台 https://m.lizhiweike.com/oc_operating/admin/dispatch/xcxsetting/
   * @param name Django后台配置对应的appID和secrect
   */
  xcx_path(params: { tag: 'scheme' | 'link'; name: string; data: any }) {
    return request.post<any>(`/oc_operating/api/dispatch/xcx_path/`, params);
  },
  /** 上传图片 */
  content_upload(params: { file_data: any; file_type: string; file_name: string }) {
    return request.upload<Iupload.Data>(`/operating_admin/content_upload`, params);
  },
  // 是否关注公众号
  is_subscribed(params: { group_name?: string }) {
    return request.get<IsSubscribedData>('/operating/api/is_subscribed', { params });
  }
};

export default Api_common;
