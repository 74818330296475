/*
 * @Date: 2020-04-01 11:03:34
 * @LastEditTime: 2021-08-19 20:12:28
 * @Description: axios拦截器
 */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { commonMeth, fromData } from 'assets/tools/index';
import qs from 'qs';

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
});
/** 原代码解释 --- 强行修改token用 */
const params: any = commonMeth.getJsonFromUrl();
const token = params.__token;
if (token) {
  commonMeth.setToken(token);
}

/** 正式链接修改为throw抛错误 */
const host_Prod = window.location.hostname === 'm.lizhiweike.com';

/**
 * 拦截器
 */
axiosInstance.interceptors.request.use(
  async config => {
    return config;
  },
  err => {
    console.error(err);
    return Promise.reject(err);
  },
);

axiosInstance.interceptors.response.use(function <T>(res: AxiosResponse<T>) {
  return new Promise((resolve, reject) => {
    const response: any = res.data;
    if (response.code !== 0) {
      !host_Prod && console.error(`%c 接收Error ${res.config.url} `, 'color:#317CBD', res.data);
      if (host_Prod) throw { message: response.msg, code: response.code };
      return reject(response);
    }
    return resolve(res);
  });
});

/** 将token带在链接上 */
const getHttpToken = async (config?: AxiosRequestConfig) => {
  let token: string | null;
  if ((window as any).__wxjs_environment === 'miniprogram') {
    token = window.localStorage.getItem('_token');
  } else {
    const Access: Window['Session']['sessionData'] = await new Promise(resolve => window.Session?.getToken(resolve));
    token = Access.token;
  }
  config = Object.assign(config?.params ?? {}, { token });
  return config;
};

/** 重写 get post */
export const request = {
  async get<T>(url: string, config?: AxiosRequestConfig) {
    return axiosInstance.get<IResponseData<T>>(url, { params: await getHttpToken(config) }).then(data => {
      return data.data;
    });
  },
  async post<T>(url: string, data?: any, config?: AxiosRequestConfig | undefined) {
    return axiosInstance.post<IResponseData<T>>(url, data, { params: await getHttpToken(config) }).then(data => {
      return data.data;
    });
  },
  async postForm<T>(url: string, data?: any, config?: AxiosRequestConfig | undefined) {
    return axiosInstance.post<IResponseData<T>>(url, qs.stringify(data), { params: await getHttpToken(config), headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).then(data => {
      return data.data;
    });
  },
  async upload<T>(url: string, data?: any, config?: AxiosRequestConfig | undefined) {
    // axios.create({ headers: { 'Content-Type': 'multipart/form-data' } });
    return axiosInstance.post<IResponseData<T>>(url, fromData(data), config).then(data => {
      return data.data;
    });
  },
};

export interface IResponseData<T> {
  router: any;
  code: number;
  msg: string;
  data: T;
}
