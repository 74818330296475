/*
 * @Date: 2021-08-17 16:20:21
 * @LastEditTime: 2021-10-15 10:46:15
 * @Description: 可使用环境变量 ${process.env.REACT_APP_PAY}
 */

import { request } from 'api/http-youke-activity';
import { Parameter, planData, Iplan, Igenerate_plan, Iuser_plan_scheme, Iget_plan_sub, IuserPlan, Iget_share_data, Icheck_new_plan } from './index.i';
import { T_idList } from 'template/learn-plan/components/questionPage';

const Api_learnPlan = {
  /** 获取问卷信息 */
  plan_parameter(params: { plan_id?: number }) {
    return request.get<Parameter>(`/course_center/api/mini_app/plan_parameter`, { params });
  },

  /** 填写完成后生成计划 */
  generate_plan(params: { plan_id?: number; id_list: T_idList }) {
    return request.post<Igenerate_plan.Data>(`/course_center/api/mini_app/generate_plan`, params);
  },

  /** 拉取用户计划 */
  user_plan(params: { user_plan_id?: number; plan_id?: number }) {
    return request.get<planData>(`/course_center/api/mini_app/user_plan`, { params });
  },

  /** 用户是否生成计划
   * @param plan_id 计划模版ID
   * @link https://gitlab.weike.fm/oc/oc-docs/blob/dqt/operating/课程体系/接口文档/大师课.md#7用户是否生成计划
   */
  user_having_plan(params: { plan_id: number }) {
    return request.get<Iplan.Data>(`/course_center/api/mini_app/user_having_plan`, { params });
  },

  /** 获取用户方案
   * @param user_plan_id 计划ID
   * @link https://gitlab.weike.fm/oc/oc-docs/blob/dqt/operating/课程体系/接口文档/大师课.md#7用户是否生成计划
   */
  user_plan_scheme(params: { user_plan_id: number }) {
    return request.get<Iuser_plan_scheme.Data>(`/course_center/api/mini_app/user_plan_scheme`, { params });
  },

  /** 获取子计划详情
   * @param plan_sub_id 子计划ID
   * @link https://gitlab.weike.fm/oc/oc-docs/blob/dqt/operating/课程体系/接口文档/大师课.md#9获取子计划数据
   */
  get_plan_sub(params: { plan_sub_id: number }) {
    return request.get<Iget_plan_sub.Data>(`/course_center/api/mini_app/get_plan_sub`, { params });
  },

  /** 生成子计划
   * @link https://gitlab.weike.fm/oc/oc-docs/blob/dqt/operating/课程体系/接口文档/大师课.md#6-生成子计划
   */
  generate_plan_sub(params: { user_plan_id: number; solar_terms_id: number; plan_id: number | string }) {
    return request.get<Iget_plan_sub.Data>(`/course_center/api/mini_app/generate_plan_sub`, { params });
  },

  /** 获取用户选项
   * @link https://gitlab.weike.fm/oc/oc-docs/blob/dqt/operating/课程体系/接口文档/大师课.md#5-获取用户选项
   */
  plan_user_select(params: { user_plan_id: number }) {
    return request.get<IuserPlan.Data>(`/course_center/api/mini_app/plan_user_select`, { params });
  },

  /** 获取分享信息 */
  get_share_data(params: { plan_id: number }) {
    return request.get<Iget_share_data.Data>(`/course_center/api/mini_app/get_share_data`, { params });
  },

  /** 删除用户计划 */
  delete_plan(params: { id: number }) {
    return request.get<any>(`course_center/api/mini_app/delete_plan`, { params });
  },

  /** 生成海报 */
  generate_share_card(params: { activity_type: string; data: { user_plan_id: number } }) {
    return request.post<any>(`/course_center/api/public/generate_share_card`, params);
  },

  /**
   * 检查计划上新
   * @link https://gitlab.weike.fm/oc/oc-docs/blob/dqt/operating/课程体系/接口文档/大师课.md
   */
  check_new_plan() {
    return request.get<Icheck_new_plan.Data>(`/course_center/api/mini_app/check_new_plan`);
  },
};

export default Api_learnPlan;
